import styled from 'styled-components';
import Carousel from 'react-styled-carousel';
import Link from 'next/link';
import theme, {media} from '../lib/theme';
import Grid from 'styled-components-grid';

const StyledGrid = styled(Grid)``;

const StyledGridUnit = styled(Grid.Unit)``;

const Line1 = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-size: ${({theme}) => theme.fontSize.default};
`;

const Line2 = styled.div`
  margin-top: ${({theme}) => theme.spacing.xsmall};
  font-size: ${({theme}) => theme.fontSize.small};
`;

const CardContent = styled.div`
  color: ${({theme}) => theme.colors.white};
  background: ${({theme}) => theme.colors.blackOverlay};
  margin: 0;
  padding: ${({theme}) => theme.spacing.default};
  opacity: 0;
  height: 100%;
  min-height: 250px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 300ms ease-in-out;
`;

const SliderWrapper = styled.div`
  width: ${({grid}) => (grid ? `calc(100% + 16px)` : `100%`)};
  margin: ${({grid}) => (grid ? `0 -8px` : null)};
  overflow: hidden;
  & > div {
    width: ${({grid}) => (!grid ? `calc(100% + 16px)` : `100%`)};
    & > div {
      width: 100%;
    }
    & > ul {
      text-align: left;
    }
  }
`;

const SliderItem = styled.a`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: unset;
  background-image: ${({image}) => (image ? `url(${image})` : null)};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  margin: ${({theme}) => theme.spacing.xsmall};
  overflow: hidden;
  text-align: center;
  & > * {
    @media (hover: none) {
      opacity: 1;
    }
  }
  &:hover {
    text-decoration: none;
    color: unset;
    > * {
      opacity: 1;
    }
  }
`;

const Slider = ({items, grid}) => (
  <SliderWrapper grid={grid}>
    {grid ? (
      <StyledGrid>
        {items?.map(({line1, line2, image, link, as}, index) => (
          <StyledGridUnit key={index} size={{xs: 1, sm: 1 / 2, md: 1 / 3}}>
            <Link href={link} as={as} passHref>
              <SliderItem image={image}>
                <CardContent>
                  <Line1>{line1}</Line1>
                  {line2 && <Line2>{line2}</Line2>}
                </CardContent>
              </SliderItem>
            </Link>
          </StyledGridUnit>
        ))}
      </StyledGrid>
    ) : (
      <Carousel
        responsive={[
          {breakPoint: theme.breakpoints.xs, cardsToShow: 1},
          {breakPoint: theme.breakpoints.sm, cardsToShow: 2},
          {breakPoint: theme.breakpoints.md, cardsToShow: 3},
        ]}
        showArrows={false}
        showDots={false}
        DotsWrapper={() => null}
        autoSlide={2500}
        pauseOnMouseOver={false}
        padding={'0px'}
        margin={`0px -${theme.spacing.xsmall}`}
        cardsToShow={1}>
        {items?.map(({line1, line2, image, link, as}, index) => (
          <Link key={index} href={link} as={as} passHref>
            <SliderItem image={image}>
              <CardContent>
                <Line1>{line1}</Line1>
                {line2 && <Line2>{line2}</Line2>}
              </CardContent>
            </SliderItem>
          </Link>
        ))}
      </Carousel>
    )}
  </SliderWrapper>
);

export default Slider;
